import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "@fontsource/quicksand";
import "@fontsource/quicksand/400.css";
import Login from "./components/Login";
import Home from "./pages/Home";
import Sidenav from "./components/layout/Sidenav";
import Dashboard from "./pages/Dashboard";
import Prices from "./pages/Prices";
import Snapshot from "./pages/Prices/Snapshot";
import { useSelector } from "react-redux";
import PublicRoute from "./components/routes/PublicRoute";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import Trande from "./pages/Prices/Trande";
import VisitsTrande from "./pages/Visits/Trande";
import VisitsSnapshot from "./pages/Visits/Snapshot";
import { ConfigProvider, theme } from "antd";
import UserManagement from "./pages/UserManagement/UserManagement";
import UserProfile from "./pages/UserManagement/UserProfile";
import NewProducts from "./pages/NewProducts";
import Promotions from "./pages/Promos/Promo";
import ActiveProducts from "./pages/ActiveProducts";
import Visits from "./pages/Visits";
import PromotionsLayout from "./pages/Promotions";
import PromotionsTrends from "./pages/Promos/PromotionsTrends";
import ResetPassword from "../src/components/ResetPassword";
import SnapshotProduct from "./pages/Products/Snapshot";
import Products from "./pages/Products";
import ProductTrends from "./pages/Products/Trends";
import SubscriptionError from "./pages/SubscriptionError";
import Openings from "./pages/Openings";
import OpeningsTrande from "./pages/Openings/Trends"
import SnapshotOpening from "./pages/Openings/Snapshot";
import { GlobalDebug } from "./components/helper/remove-consoles";

const App = () => {
  GlobalDebug(true)
  console.log(
    "\n                  ,d\"=≥,.,qOp,\n                 ,7'  ''²$(  )\n                ,7'      '?q$7'\n             ..,$$,.\n   ,.  .,,--***²\"\"²***--,,.  .,\n ²   ,p²''              ''²q,   ²\n:  ,7'                      '7,  :\n ' $      ,db,      ,db,      $ '\n  '$      ²$$²      ²$$²      $'    Meaningful Vision.\n  '$                          $'        \n   '$.     .,        ,.     .$'\n    'b,     '²«»«»«»²'     ,d'\n     '²?bn,,          ,,nd?²'\n       ,7$ ''²²²²²²²²'' $7,\n     ,² ²$              $² ²,\n     $  :$              $:  $\n     $   $              $   $\n     'b  q:            :p  d'\n      '²«?$.          .$?»²'\n         'b            d'\n       ,²²'?,.      .,?'²²,\n      ²==--≥²²==--==²²≤--==²\n"
  );

  const { loading } = useSelector((state) => state.alerts);
  const { themeCustome } = useSelector((state) => state.darklightmode);
  const AccessControll = localStorage.getItem("access")?.split(",");
  return (
    <div className='App'>
      <ConfigProvider
        theme={{
          algorithm: themeCustome ? theme.lightAlgorithm : theme.darkAlgorithm,
        }}
      >
        <Router>
          {loading && (
            <div className='spinner-parent'>
              <div className='spinner-border text-danger' role='status' style={{ width: " 8rem", height: "8rem" }}></div>
            </div>
          )}
          <Switch>
            <Route exact path='/'>
              <PublicRoute>
                <Login />
              </PublicRoute>
            </Route>
            <Route exact path='/reset-password'>
              <ResetPassword />
            </Route>
            <Sidenav>
              <ProtectedRoute>
                <Route path='/dashboard'>
                  <Dashboard />
                </Route>
                <Route path='/home'>
                  <Home />
                </Route>
                <Route path='/prices' component={Prices} />
                <Route path='/Products' component={Products} />
                <Route path='/prices-snapshot'>{AccessControll?.includes("Prices") ? <Snapshot /> : <SubscriptionError />}</Route>
                <Route path='/prices-trends'>{AccessControll?.includes("Prices") ? <Trande /> : <SubscriptionError />}</Route>
                <Route path='/user-management' component={UserManagement} />
                <Route path='/profile' component={UserProfile} />
                <Route path='/New-This-Period'>{AccessControll?.includes("Products") ? <NewProducts /> : <SubscriptionError />}</Route>
                <Route path='/Active-This-Period'>{AccessControll?.includes("Products") ? <ActiveProducts /> : <SubscriptionError />}</Route>
                <Route path='/Promotions-snapshot'>{AccessControll?.includes("Promotions") ? <Promotions /> : <SubscriptionError />}</Route>
                <Route path='/Promotions-trends'>{AccessControll?.includes("Promotions") ? <PromotionsTrends /> : <SubscriptionError />}</Route>
                <Route path='/Promotions' component={PromotionsLayout} />
                <Route path='/traffic-trends'>{AccessControll?.includes("Visits") ? <VisitsTrande /> : <SubscriptionError />}</Route>
                <Route path='/traffic-snapshot'>{AccessControll?.includes("Visits") ? <VisitsSnapshot /> : <SubscriptionError />}</Route>
                <Route path='/products-snapshot'>{AccessControll?.includes("Products") ? <SnapshotProduct /> : <SubscriptionError />}</Route>
                <Route path='/products-trends'>{AccessControll?.includes("Products") ? <ProductTrends /> : <SubscriptionError />}</Route>
                <Route path="/openings-snapshot"><SnapshotOpening/></Route>
                <Route path='/visits' component={Visits} />
                <Route path='/Openings' component={Openings} />
                {/* <Route path="/openings-trends">{AccessControll?.includes("Openings")?<OpeningsTrande/>:<SubscriptionError/>}</Route> */}
                <Route path="/openings-trends" component={OpeningsTrande}/>

                <Route path='/subscription-error' component={SubscriptionError} />
              </ProtectedRoute>
            </Sidenav>
          </Switch>
        </Router>
      </ConfigProvider>
    </div>
  );
};
export default App;
