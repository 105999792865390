import { URL } from "../Helper/MainURL"
const token=localStorage.getItem('token')
export const Apifordataget =(api,token)=>{
  return
    return new Promise((resolve, reject) => {
        try {
            fetch(`${URL}${api}`, {
              method: 'get',
              headers: {
                // Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            })
              .then((data) => {
                data.json().then(function (resp) {
                    resolve(resp)
                })
              })
              .catch(function (error) {
                console.log('Request failed', error)
              })
          } catch (err) {
            console.log('ERR>>>', err)
          }
    })
}
export const ApifordataPost =(api,data)=>{
  const token1=localStorage.getItem('token')

  return new Promise((resolve, reject) => {
      try {
        fetch(`${URL}${api}`, {
            method: 'Post',
            headers: {
              Authorization: `Bearer ${token1}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
            .then((data) => {
              data.json().then(function (resp) {
                  resolve(resp)
              })
            })
            .catch(function (error) {
              console.log('Request failed', error)
            })
        } catch (err) {
          console.log('ERR>>>', err)
        }
  })
}