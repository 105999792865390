import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { showLight} from "../../redux/cutomeTheme";
import { Spin } from "antd";

const BarStackType = (props) => {
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (props?.data?.promotype) {
      let tempArray = [];
      props.data.promotype.map((e) => {
        for (let i = 0; i < Object.keys(e).length; i++) {
          tempArray.push({
            name: Object.keys(e)[i],
            data: e[Object.keys(e)[i]],
          });
        }
      });
      setSeries(tempArray);
      setTimeout(()=>{
        setLoading(false); 
      },3000)
    }
  }, [props?.data?.promotype]);

  const options = {
    chart: {
      type: "bar",
      background: showLight ? "#fff" : "#333",
      stacked: true,
      height: 500,
      stackType: '100%',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true
        },
        export: {
      
          png: {
            filename: "Meaningful Vision",
           
          },
       
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        
        barHeight: "100%", 
        columnWidth: '90%', 
      },
      
    },
    xaxis: {
      categories: props?.data?.Channels ? props.data.Channels : [],
    },
    yaxis: {
      title: {
        text: "Active Promotions by Type",
        style: {
          fontSize: '16px', 
          fontWeight: 'light', 
          color: '#000000', 
        },
      },
      
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} `,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    colors: [ '#4e9ca4','#f685a0', '#8ccfdd', '#53f5d1', '#ae8cf4',"#006085"],
    dataLabels: {
      style: {
          colors: ['#000000'],
          fontWeight:"light"
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
  };

  return (
    <div>
      {loading ? (
           <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1000
          }}> <Spin></Spin> </div>
      ) : (
        <Chart options={options} series={series} type="bar" height={600} />
      )}
    </div>
  );
};

export default BarStackType;
