import React, { useEffect, useState } from "react";
import { Table, Row, Col, Pagination, Modal, Button, Image, Space, Tooltip, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CaretUpOutlined, CaretDownOutlined, FilterOutlined } from "@ant-design/icons";
import { showFilterModal, hideFilterModal } from "../redux/filterModal";
import ViewCustomized from "../assets/images/icons/ViewCustomized.png";
import FilterModal from "./FilterModal";
const NewProducts = (props) => {
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [productData, setProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [modalflag, setModalFlag] = useState(false);
  const [img, setimg] = useState("");
  const [ImageName, setImageName] = useState("");
  const [calApi, setCalApi] = useState(0);
  const [loader1, setLoader1] = useState(false);

  const { loader } = useSelector((state) => state.loader);
  const [sorter, setSorter] = useState({
    columnKey: "Item",
    order: "ascend",
  });
  const { TimescalesTrend, MarketSegment, CompetitiveSet, Category, ProteinType } = filterValues;
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPage(1);
    getProductsData();
  }, [calApi]);
  useEffect(() => {
    getProductsData();
  }, [currentPage, sorter]);
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const getProductsData = async () => {
    setLoader1(true);

    let payLoad = {
      page_number: currentPage,
      filters: {
        Timescales: filterValues.TimescalesTrend,
        Market_Segment: filterValues.MarketSegment,
        Competitive_Set: filterValues.CompetitiveSet,
        Category: filterValues.Category,
        Protein_Type: filterValues.ProteinType,
      },
      dashboard_type: 1,
      sort_column: sorter.columnKey,
      sort_type: sorter.order == "ascend" ? "ASC" : "DESC",
      email: localStorage.getItem("email"),
    };
    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`https://mvstaging.azurewebsites.net/product/?${randomParam}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoad),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setProductData(data?.data);
          setTotalPage(data?.total_count);
          setLoader1(false);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };

  const columns = [
    {
      title: "Chain",
      dataIndex: "Chain",
      key: "Chain",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Protein Type",
      dataIndex: "ProteinType",
      key: "ProteinType",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Item ",
      dataIndex: "Item",
      key: "Item",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Prices ",
      dataIndex: "Prices",
      key: "Prices",
      sorter: true,
      render: (value) => {
        if (!value || value == "0.00") {
          return <>-</>;
        }
        return <a>{value}</a>;
      },
      sorterIcon: ({ sortOrder }) => {
        if (sortOrder === "descend") {
          return <CaretDownOutlined />;
        }
        if (sortOrder === "ascend") {
          return <CaretUpOutlined />;
        }
        return <CaretUpOutlined />;
      },
    },
    {
      title: "Picture ",
      dataIndex: "Picture",
      key: "Picture",
      render: (value, records) => {
        if (!value || value == "0.00") {
          return (
            <img
              src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${value}`}
              width='100'
              height='100'
              onClick={(e) => {
                setModalFlag(true);
                setimg(value);
                setImageName(records.Item);
              }}
            />
          );
        }
        return (
          <img
            src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${value}`}
            width='100'
            height='100'
            onClick={(e) => {
              setModalFlag(true);
              setimg(value);
              setImageName(records.Item);
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Row className='mb-2'>
        <Col span={8} className='p-1'>
          <span className='bg-white text-dark p-2 rounded'>
            <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
              Products
            </span>{" "}
            <span style={{ color: "#1DB9DE" }} className='fw-bold'>
              {" "}
              |
            </span>{" "}
            <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
              Table
            </span>
          </span>
        </Col>
        <Col span={8} className='text-center'></Col>
        <Col span={8} className='p-1 text-center'>
          {" "}
          <Button onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
            Filters
          </Button>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Space size={[0, "small"]} wrap>
          <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Timescales:
              {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              MarketSegment && MarketSegment.length > 0
                ? MarketSegment.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Segment:
              {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              CompetitiveSet && CompetitiveSet.length > 0
                ? CompetitiveSet.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Competitive:
              {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              Category && Category.length > 0
                ? Category.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              Category:
              {Category && Category.length > 0 ? `${Category[0]}  ` : "Not selected"}{" "}
            </Tag>
          </Tooltip>
          <Tooltip
            placement='bottomLeft'
            title={
              ProteinType && ProteinType.length > 0
                ? ProteinType.map((e) => {
                    return <li>{e}</li>;
                  })
                : "Not selected"
            }
          >
            <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
              ProteinType:
              {ProteinType && ProteinType.length > 0
                ? `${ProteinType[0]} 
             
              `
                : "Not selected"}{" "}
            </Tag>
          </Tooltip>
        </Space>
      </Row>

      <Table
        columns={columns}
        dataSource={productData}
        pagination={false}
        loading={loader1}
        scroll={{
          x: 800,
          y: "60vh",
        }}
        onChange={( sorter) => {
          setSorter({
            ...sorter,
          });
        }}
      />
      <div className='d-flex justify-content-end p-4'>
        <Pagination
          current={currentPage}
          defaultCurrent={currentPage}
          total={totalPage}
          pageSize={50}
          onChange={(e) => {
            setCurrentPage(e);
          }}
          showSizeChanger={false}
        />
      </div>

      <Modal
        open={modalflag}
        justifyContent='center'
        destroyOnClose
        onCancel={(e) => {
          setModalFlag(false);
        }}
        footer={false}
        size='large'
        width={600}
        height={600}
        title={<div className='text-left fs-6'>{ImageName}</div>}
      >
        <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>
          <img src={`https://mvtool.s3.eu-west-2.amazonaws.com/images/product/${img}`} width='300' height='300px' />
        </div>{" "}
      </Modal>
      <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} setCalApi={setCalApi} calApi={calApi} name={"Product"} />
    </>
  );
};

export default NewProducts;
