import React from "react";
import { Row, Col, Image } from "antd";
import Traffic from "../assets/images/Traffic.png";
import Opening from "../assets/images/Opening.png";
import L4L from "../assets/images/L4L.png";
import Prices from "../assets/images/Prices.png";
import NewProduct from "../assets/images/NewProducts.png";
import Promo from "../assets/images/Promo.png";
import homepagejson from "../components/helper/homepage.json";
const imgUrl = [Traffic, Opening, L4L, NewProduct, Prices, Promo];
function Dashboard() {
  return (
    <>
      <div className='layout-content' id='cardlayout'>
        <Row className=' p-2' justify={"center"}>
          {homepagejson?.cardDetails.map((e, key) => {
            return (
              <Col span={7} className='p-4'>
                {e.link != "" ? (
                  <a className='card  m-2 shadow-lg  bg-white rounded' href={e.link} style={{ textDecoration: "none" }}>
                    <div className='card-header text-center text-white  border-0 p-4 p-4' style={{ background: e.color }}>
                      <span className='font-weight-bold' style={{ fontWeight: 600, fontSize: "24px", fontFamily: "Quicksand", fontFamily: "Quicksand" }}>
                        {e.name}
                      </span>
                    </div>
                    <div className='card-body text-center border-none ' style={{ background: e.discriptionColor, height: "200px" }}>
                      <Image preview={false} width={120} src={imgUrl[key]} className='mt-4' />
                    </div>
                  </a>
                ) : (
                  <a className='card  m-2 shadow-lg  bg-white rounded' style={{ textDecoration: "none" }}>
                    <div className='card-header text-center text-white  border-0 p-4 p-4' style={{ background: e.color }}>
                      <span className='font-weight-bold' style={{ fontWeight: 600, fontSize: "24px", fontFamily: "Quicksand", fontFamily: "Quicksand" }}>
                        {e.name}
                      </span>
                    </div>
                    <div className='card-body text-center border-none ' style={{ background: e.discriptionColor, height: "200px" }}>
                      <Image preview={false} width={120} src={imgUrl[key]} className='mt-4' />
                    </div>
                  </a>
                )}
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}
export default Dashboard;
