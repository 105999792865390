import React, { useEffect, useState } from "react";
import { Space, Table, Row, Col, Button, Image, Tabs, Pagination, Popconfirm, message } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import CreateUserModal from "../UserManagement/CreateUserModal";
import CreateOrganization from "../UserManagement/CreateOrganization";
import { hideLoader, showLoader } from "../../redux/tableLoader";
function UserManagement(props) {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(props?.location?.state?.activeTab ? props.location.state.activeTab : 1);
  const [data, setData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Organizations");
  const [openModal, setopenModal] = useState(false);
  const [openModal2, setopenModal2] = useState(false);
  const { loader } = useSelector((state) => state.loader);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [currentPageorg, setcurrentPageorg] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [initialState, setinitialState] = useState([]);
  const [competitive_set, setcompetitive_set] = useState([]);
  const [brands, setbrands] = useState([]);

  const [sorter, setSorter] = useState({
    columnKey: "Product",
    order: "ascend",
  });
  useEffect(() => {
    getDropDownValues();
    getBrandValue();
    getOrganization();
    getUserData();
  }, []);
  useEffect(() => {
    getUserData();
  }, [activeTab, openModal, currentPageorg, openModal2]);
  const getUserData = async () => {
    dispatch(showLoader());
    let payload = {
      board_type: activeTab,
      page_number: currentPageorg,
      sort_column: "Organization",
      sort_type: "ASC",
    };
    await fetch(`https://mvstaging.azurewebsites.net/get-user/`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          setData(data?.data);
          setTotalPage(data.total_count);
          dispatch(hideLoader());
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getDropDownValues = async () => {
    await fetch(`https://mvstaging.azurewebsites.net/organization/organization-dropdown`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        response.json().then(function (data) {
          setDropdownValues(data);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getBrandValue = async () => {
    await fetch(`https://mvstaging.azurewebsites.net/filter/organization`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        response.json().then(function (data) {
          setcompetitive_set(data?.Brand);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const getOrganization = async () => {
    await fetch(`https://mvstaging.azurewebsites.net/filter/user-organization`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        response.json().then(function (data) {
          setbrands(data?.Brand);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const handleEdit = async (item, records) => {
    setinitialState(records);
    dispatch(showLoader());
    if (activeTab == 1) {
      setopenModal(true);
    } else {
      setopenModal2(true);
    }
  };
  const handleDelete = async (item, records) => {
    dispatch(showLoader());
    let payload = {
      organization: records.Organization,
    };
    let payloadUser = {
      email: records.User,
    };

    await fetch(`https://mvstaging.azurewebsites.net/${activeTab == 1 ? "organization/delete" : "delete-user/"} `, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activeTab == 1 ? payload : payloadUser),
    })
      .then(function (response) {
        response.json().then(function (data) {
          message.success(data?.message);
          getUserData();

          dispatch(hideLoader());
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const onChangeTab = (e) => {
    setcurrentPageorg(1);
    if (e == 1 || e == "1") {
      setSelectedTab("Organizations");
    } else if (e == 2 || e == "2") {
      setSelectedTab("users");
    }
    setActiveTab(e);
    return;
  };
  const handleCloseModal = () => {
    setopenModal(false);
    setinitialState([]);
  };
  const handleCloseModal2 = () => {
    setopenModal2(false);
    setinitialState([]);
  };

  const Label = ["", "Organizations", "Users"];
  const organisation = [
    {
      title: "Organisation Name",
      dataIndex: "Organization",
      key: "Organization",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Chains",
      dataIndex: "Chains",
      key: "Chains",
      fixed: true,
      width: 100,
      sorter: true,
      render: (text) => {
        if (text) {
          return text.join(", ");
        }
      },
    },
    {
      title: "Country",
      dataIndex: "Country",
      key: "Country",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Sections",
      dataIndex: "dashboard",
      key: "dashboard",
      fixed: true,
      width: 100,
      sorter: true,
      render: (text) => {
        if (text) {
          return text.join(", ");
        }
      },
    },

    {
      title: "Action",
      dataIndex: "Product",
      key: "Product",
      fixed: true,
      width: 100,
      render: (item, records) => {
        return (
          <Space>
            <Button
              onClick={(e) => {
                handleEdit(item, records);
              }}
              icon={<EditOutlined />}
            ></Button>
            <Popconfirm title='Delete  organization' description='Are you sure to delete this organization?' onConfirm={(e) => handleDelete(item, records)} okType='default' okText='Yes' cancelText='No'>
              <Button icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const users = [
    {
      title: "Organisation ",
      dataIndex: "Organization",
      key: "Organization",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "User ",
      dataIndex: "User",
      key: "User",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Role ",
      dataIndex: "Roles",
      key: "Roles",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "First Name ",
      dataIndex: "First_Name",
      key: "First_Name",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Last Name ",
      dataIndex: "Last_Name",
      key: "Last_Name",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Phone Number ",
      dataIndex: "Phone_number",
      key: "Phone_number",
      fixed: true,
      width: 100,
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "Product",
      key: "Product",
      fixed: true,
      width: 100,
      render: (item, records) => {
        return (
          <Space>
            <Button
              onClick={(e) => {
                handleEdit(item, records);
              }}
              icon={<EditOutlined />}
            ></Button>
            <Popconfirm title='Delete  user' description='Are you sure to delete this user?' onConfirm={(e) => handleDelete(item, records)} okType='default' okText={"Yes"} cancelText='No'>
              <Button icon={<DeleteOutlined />}></Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className='container-fluid'>
        <Row>
          <Col span={8} className='p-1'>
            <span className='bg-white text-dark p-2 rounded'>
              <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
                {Label[activeTab]}
              </span>{" "}
              <span style={{ color: "#1DB9DE" }} className='fw-bold'>
                {" "}
              </span>
            </span>
          </Col>
          <Col span={8} className='text-center'></Col>

          <Col span={8} className='p-1 text-center'>
            <Space>
              {selectedTab == "Organizations" && (
                <Button
                  block
                  className='mb-4 export-button1 text-white'
                  icon={<PlusOutlined />}
                  size={"large"}
                  onClick={(e) => {
                    setopenModal(true);
                  }}
                >
                  New Organization
                </Button>
              )}
              {selectedTab != "Organizations" && (
                <Button
                  block
                  className='mb-4 export-button1 text-white'
                  icon={<PlusOutlined />}
                  size={"large"}
                  onClick={(e) => {
                    setopenModal2(true);
                  }}
                >
                  New User
                </Button>
              )}
            </Space>
          </Col>
        </Row>

        <Tabs
          defaultActiveKey={activeTab}
          className='activetabcolor '
          style={{ textAlign: "center" }}
          onChange={onChangeTab}
          items={new Array(3).fill(null).map((_, i) => {
            return {
              label: <div className='text-center'>{Label[i + 1]}</div>,
              key: i + 1,
              children: (
                <>
                  <Table
                    columns={selectedTab == "Organizations" ? organisation : users}
                    dataSource={data}
                    loading={loader}
                    pagination={false}
                    onChange={(val, filter, sorter, extra) => {
                      setSorter({
                        ...sorter,
                      });
                    }}
                    scroll={{
                      x: 920,
                      y: "48vh",
                    }}
                  />
                  <div className='d-flex justify-content-end p-4'>
                    <Pagination
                      defaultCurrent={currentPageorg}
                      total={totalPage}
                      pageSize={20}
                      onChange={(e) => {
                        setcurrentPageorg(e);
                      }}
                      hideOnSinglePage={false}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ),
            };
          })}
        />
      </div>
      <CreateUserModal openModal={openModal} handleCloseModal={handleCloseModal} dropdownValues={dropdownValues} initialState={initialState} />
      <CreateOrganization openModal2={openModal2} handleCloseModal2={handleCloseModal2} competitive_set={competitive_set} brands={brands} initialState={initialState} />
    </>
  );
}

export default UserManagement;
