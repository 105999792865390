import React from "react";
import { useHistory } from "react-router-dom";

function ProtectedRoute(props) {
  const history = useHistory();
  if (localStorage.getItem("token")) {
    return <div>{props.children}</div>;
  } else {
    return history.push("/");
  }
}

export default ProtectedRoute;
