import React, { useEffect, useState } from "react";
import { Space, Table, Row, Col, Button, Image, Tabs, Tag, Radio, Pagination, Tooltip, notification, message } from "antd";
import ViewCustomized from "../../assets/images/icons/ViewCustomized.png";
import { DownloadOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import FilterModal from "../FilterModal";
import { useDispatch, useSelector } from "react-redux";
import { showFilterModal, hideFilterModal } from "../../redux/filterModal";
import PapaParse from "papaparse";
import { useLocation } from "react-router-dom";


const Label = ["", "Prices by Item", "Prices by Category"];

const columns = [
  {
    title: "Chain",
    dataIndex: "Brand",
    key: "Brand",
    sorter: true,
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: true,
    render: (text) => <a>{text}</a>,
  },
];

function Trande(props) {
  const location=useLocation()
  const [activeTab, setActiveTab] = useState(location.state?.activeTab || "1");
  const [data, setData] = useState([]);
  const [months, setMonths] = useState([]);
  const [mode, setMode] = useState(1);

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setCurrentPageTrend(1);
  };
  const { isModalOpen } = useSelector((state) => state.filtermodal);
  const filterValues = useSelector((state) => state.filterValue);
  const [calApi, setCalApi] = useState(0);
  const [currentPage1, setCurrentPageTrend] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [loadingExport, setloadingExport] = useState(false);
  const [loader3, setloader3] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const { City, TimescalesTrend, MarketSegment, CompetitiveSet, Category, ProteinType, Channel,Item, ProductSize} = filterValues;
  const [sorter, setSorter] = useState({
    columnKey: "Product",
    order: "ascend",
  });
  const [sorter1, setSorter1] = useState({
    columnKey: "Brand",
    order: "ascend",
  });
  const dispatch = useDispatch();
  const showModal = () => {
    dispatch(showFilterModal());
  };
  const handleOk = () => {
    dispatch(hideFilterModal());
  };
  const handleCancel = () => {
    dispatch(hideFilterModal());
  };
  useEffect(() => {
    setCurrentPageTrend(1);
    getData(filterValues);
  }, [calApi]);
  useEffect(
    (e) => {
      getData(filterValues);
    },
    [activeTab, mode, currentPage1, sorter, sorter1]
  );

  const getData = async (data) => {
    setloader3(true);
    let payload = {
      page_number: currentPage1,
      filters: {
        TimescalesTrend: data?.TimescalesTrend,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        City: data?.City,
        Item: data?.Item,
        Channel: data?.Channel,
        Product_size: data?.ProductSize,
      },

      sort_column: activeTab == 1 ? sorter.columnKey : sorter1.columnKey,
      sort_type: activeTab == 1 ? (sorter.order == "ascend" ? "ASC" : "DESC") : sorter1.order == "ascend" ? "ASC" : "DESC",
      table_type: parseInt(activeTab),
      dashboard_type: mode,
      email: localStorage.getItem("email"),
    };
    const randomParam = Math.random().toString(36).substring(7);

    await fetch(`https://mvstaging.azurewebsites.net/trends/?${randomParam}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      cache: "default",
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          if (data.message == "Invalid token" || data.message == "Token has expired") {
            localStorage.clear();
            window.location.reload();
          }
          setData(data?.data);
          setMonths(data?.months);
          setTotalPage(data?.total_count);
          setloader3(false);
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const onChangeTab = (e) => {
    setActiveTab(e);
    setCurrentPageTrend(1);
  };

  for (let i = 0; i < months?.length; i++) {
    let width = 50;

    if (months[i] == "Brand") {
      width = 200;
    }
    const newColumn = {
      title: months[i],
      dataIndex: months[i],
      key: months[i],
      width: 200,
      fixed: "left",
    };
    columns.push(newColumn);
  }

  columns.push({
    title: "Chain",
    dataIndex: "Brand",
    key: "Brand",
    sorter: true,
    width: 100,
    render: (text) => <a>{text}</a>,
  });
  columns.push({
    title: "Category",
    dataIndex: "Category",
    key: "Category",
    sorter: true,
    width: 100,
    render: (text) => <a>{text}</a>,
  });

  const getDownloadData = (data) => {
    setloadingExport(true);
    let payload = {
      page_number: currentPage1,
      filters: {
        TimescalesTrend: data?.TimescalesTrend,
        Market_Segment: data?.MarketSegment,
        Competitive_Set: data?.CompetitiveSet,
        Category: data?.Category,
        Protein_Type: data?.ProteinType,
        City: data?.City,
        Item: data?.Item,
        Channel: data?.Channel,
        Product_size: data?.ProductSize,
      },
      sort_column: activeTab == 1 ? sorter.columnKey : sorter1.columnKey,
      sort_type: activeTab == 1 ? (sorter.order == "ascend" ? "ASC" : "DESC") : sorter1.order == "ascend" ? "ASC" : "DESC",
      table_type: activeTab,
      dashboard_type: mode,
      email: localStorage.getItem("email"),
    };
    fetch(`https://mvstaging.azurewebsites.net/exports/trends`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      cache: "default",
      body: JSON.stringify(payload),
    })
      .then(function (response) {
        response.json().then(function (data) {
          downloadcsv(data);
          openNotification("bottomLeft");
        });
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };
  const downloadcsv = (data) => {
    if (!data.data || data.success == false || data?.data?.length == 0) {
      message.error("No data to download");
      setloadingExport(false);
      return;
    }

    let fields = new Array();
    data?.months.map((e) => {
      fields.push(e);
    });
    var data = {
      fields: fields,
      data: data?.data?.length > 0 ? data?.data : [],
    };

    var csv = PapaParse.unparse(data);
    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    csvURL = window.URL.createObjectURL(csvData);
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", `data.csv`);
    tempLink.click();
    setloadingExport(false);
  };
  const openNotification = (placement) => {
    api.info({
      message: `Downloading`,
      description: "While your file is being retrieved, feel free to continue using the tool.",
      placement,
    });
  };
  return (
    <>
      {contextHolder}
      <div className='container-fluid'>
        <Row>
          <Col span={8} className='p-1'>
            <span className='bg-white text-dark p-2 rounded'>
              <span className='fw-bold page-header' style={{ fontFamily: "Quicksand" }}>
                {Label[activeTab]}
              </span>{" "}
              <span style={{ color: "#1DB9DE" }} className='fw-bold'>
                {" "}
                |
              </span>{" "}
              <span className='page-header-table' style={{ fontFamily: "Quicksand" }}>
                Table
              </span>
            </span>
          </Col>
          <Col span={8} className='text-center'>
            <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }} size='large' defaultValue={1}>
              <Radio.Button value={1}>No Comparison</Radio.Button>

              <Radio.Button value={2}>vs Last Period</Radio.Button>
              <Radio.Button value={3}>vs Last Year</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={5} className='p-1 text-center'></Col>
          <Col span={3} className='p-1 text-center'>
            <Space>
              <Button block onClick={showModal} className='mb-4' icon={<Image src={ViewCustomized} width={14} preview={false} />} size={"large"}>
                Filters
              </Button>
              <Button
                block
                className='mb-4 export-button '
                icon={<DownloadOutlined />}
                size={"large"}
                loading={loadingExport}
                onClick={() => {
                  getDownloadData(filterValues);
                }}
              ></Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Space size={[0, "small"]} wrap>
            <Tooltip placement='bottomLeft' title={TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : " All"}>
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Timescales:
                {TimescalesTrend && TimescalesTrend.length > 0 ? TimescalesTrend.join("").substring(0, 10) + " - " + TimescalesTrend.join("").substring(10, 20) : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                MarketSegment && MarketSegment.length > 0
                  ? MarketSegment.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Segment:
                {MarketSegment && MarketSegment.length > 0 ? `${MarketSegment[0]} ${MarketSegment?.length ? MarketSegment.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                CompetitiveSet && CompetitiveSet.length > 0
                  ? CompetitiveSet.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Competitive:
                {CompetitiveSet && CompetitiveSet.length > 0 ? `${CompetitiveSet[0]} ${CompetitiveSet?.length > 1 ? CompetitiveSet.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                Category && Category.length > 0
                  ? Category.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected "
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Category:
                {Category && Category.length > 0 ? `${Category[0]} ` : " Not selected"}{" "}
                {/* <span
                  className='cursor-pointer'
                  onClick={(e) => handleClose(4)}
                  style={{
                    cursor: "pointer",
                    borderRadius: "3px",
                    padding: "2px",
                  }}
                >
                  <Image src={Vector1} width={8} height={8} preview={false} />
                </span> */}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                ProteinType && ProteinType.length > 0
                  ? ProteinType.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                ProteinType:
                {ProteinType && ProteinType.length > 0 ? `${ProteinType[0]} ` : "Not selected"} {/* {ProteinType ? ProteinType : "All "}{" "} */}
              </Tag>
            </Tooltip>
            <Tooltip
              placement='bottomLeft'
              title={
                Item && Item.length > 0
                  ? Item.map((e) => {
                      return <li>{e}</li>;
                    })
                  : "Not selected"
              }
            >
              <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                Item:
                {Item && Item.length > 0 ? `${Item[0]} ${Item?.length > 1 ? Item.length : ""} ` : "Not selected"}{" "}
              </Tag>
            </Tooltip>
            {
              <Tooltip
                placement='bottomLeft'
                title={
                  Channel && Channel.length > 0
                    ? Channel.map((e) => {
                        return <li>{e}</li>;
                      })
                    : "Not selected"
                }
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Channel:
                  {Channel && Channel.length > 0 ? `${Channel[0]} ${Channel?.length > 1 ? Channel.length : ""} ` : " Not selected"}{" "}
                  {/* <span
                    className='cursor-pointer'
                    onClick={(e) => handleClose(6)}
                    style={{
                      cursor: "pointer",
                      borderRadius: "3px",
                      padding: "2px",
                    }}
                  >
                    <Image src={Vector1} width={8} height={8} preview={false} />
                  </span> */}
                </Tag>
              </Tooltip>
            }
            {(activeTab == 2 || activeTab == 1) && (
              <Tooltip
                placement='bottomLeft'
                title={
                  City && City.length > 0
                    ? City.map((e) => {
                        return <li>{e}</li>;
                      })
                    : "Not selected"
                }
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  City:
                  {/* {City && City.length > 0 ? `${City[0]} ` : "Not selected"}{" "} */}
                  {City && City.length > 0 ? `${City[0]} ${City?.length > 1 ? City.length : ""} ` : "Not selected"}{" "}
                  {/* <span
                    className='cursor-pointer'
                    onClick={(e) => handleClose(7)}
                    style={{
                      cursor: "pointer",
                      borderRadius: "3px",
                      padding: "2px",
                    }}
                  >
                    <Image src={Vector1} width={8} height={8} preview={false} />
                  </span> */}
                </Tag>
              </Tooltip>
            )}
            {activeTab == 1 && (
              <Tooltip
                placement='bottomLeft'
                title={
                  ProductSize && ProductSize.length > 0
                    ? ProductSize.map((e) => {
                        return <li>{e}</li>;
                      })
                    : "Not selected"
                }
              >
                <Tag bordered={false} size='large' className='my-custom-tag tag-csss' style={{ borderRadius: "10px" }}>
                  Product Size:
                  {ProductSize && ProductSize.length > 0 ? `${ProductSize[0]} ` : "Not selected"}{" "}
                </Tag>
              </Tooltip>
            )}
          </Space>
        </Row>
        <Tabs
          defaultActiveKey={activeTab}
          className='activetabcolor'
          onChange={onChangeTab}
          items={new Array(2).fill(null).map((_, i) => {
            return {
              label: `${Label[i + 1]}`,
              key: i + 1,
              children: (
                <>
                  {months && (
                    <Table
                      columns={months.map((e) => {
                        let width = 90,
                          fixed = false,
                          sort = false;
                        if (e == "Item") {
                          width = 240;
                          fixed = true;
                          sort = true;
                        }
                        if (e == "Brand" || e == "Category") {
                          width = 170;
                          fixed = true;
                          sort = true;
                        }
                        if (e == "Protein_Type") {
                          width = 110;
                          fixed = true;
                          sort = true;
                        }
                        if (e == "Price_range") {
                          width = 110;
                          fixed = true;
                          sort = true;
                        }

                        return {
                          title: e == "Price_range" ? "Price range" : e == "Protein_Type" ? "Protein Type" : e,
                          dataIndex: e,
                          key: e,
                          width: width,
                          fixed: fixed,
                          sorter: sort,
                          sorterIcon: ({ sortOrder }) => {
                            if (sortOrder === "descend") {
                              return <CaretDownOutlined />;
                            }
                            if (sortOrder === "ascend") {
                              return <CaretUpOutlined />;
                            }
                            return <CaretUpOutlined />;
                          },

                          render: (items) => {
                            if (!items) {
                              return <span style={{ fontWeight: 700 }}>-</span>;
                            } else {
                              return <>{items}</>;
                            }
                          },
                        };
                      })}
                      dataSource={data}
                      loading={loader3}
                      pagination={false}
                      scroll={{
                        x: 920,
                        y: "48vh",
                      }}
                      onChange={(val, filter, sorter, extra) => {
                        if (activeTab == 1) {
                          setSorter({
                            ...sorter,
                          });
                        } else {
                          setSorter1({
                            ...sorter,
                          });
                        }
                      }}
                    />
                  )}
                  <div className='d-flex justify-content-end p-4'>
                    <Pagination
                      current={currentPage1}
                      defaultCurrent={currentPage1}
                      total={totalPage}
                      pageSize={100}
                      onChange={(e) => {
                        setCurrentPageTrend(e);
                      }}
                      showSizeChanger={false}
                    />
                  </div>
                </>
              ),
            };
          })}
        />
      </div>
       <FilterModal showModal={showModal} isModalOpen={isModalOpen} handleCancel={handleCancel} handleOk={handleOk} activeTabTrends={activeTab} closeIcon={null} setCalApi={setCalApi} calApi={calApi} dashboard={"Prices"}/>
    </>
  );
}

export default Trande;
