import { createSlice } from "@reduxjs/toolkit";



export const saveCloseFlag = createSlice({
    name: "saveCloseFlag",
    initialState: {
     saveCloseFlag:false
    },
    reducers: {
      setsaveCloseFlag: (state, action) => {
       
        const {saveCloseFlag} = action.payload;
        
       
       
        state.saveCloseFlag = saveCloseFlag;
       
      }
    }
  });
  
  export const { setsaveCloseFlag } = saveCloseFlag.actions;
  
  export default saveCloseFlag.reducer;