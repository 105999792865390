import { useHistory } from "react-router-dom";

function PublicRoute(props) {
  const history = useHistory();
  const token = localStorage.getItem("token");
  if (token) {
    return history.push("/home");
  } else {
    return props.children;
  }
}

export default PublicRoute;
