import {configureStore}  from "@reduxjs/toolkit"
import {combineReducers} from "redux"
import { alertSlice } from "./alertSlice"
import { tableLoader } from "./tableLoader"
import { filterModal } from "./filterModal"
import { customeTheme } from "./cutomeTheme"
import {filterValues} from "./filterValues"
import {saveCloseFlag} from "./saveCloseFlag"

const rootReducer=combineReducers({
    alerts:alertSlice.reducer,
    loader:tableLoader.reducer,
    filtermodal:filterModal.reducer,
    darklightmode:customeTheme.reducer,
    filterValue:filterValues.reducer,
    saveCloseFlag:saveCloseFlag.reducer

})

const store =configureStore({
    reducer:rootReducer
})

export default store;