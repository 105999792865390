const SubscriptionError = (props) => {
  return (
    <div className='container-fluid p-3'>
      <section className='page_404'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 '>
              <div className='col-sm-10 col-sm-offset-1  text-center'>
                <div className='four_zero_four_bg'></div>
                 <div className='contant_box_404 mt-4'>
                  <h3 className='h4'>You don't have a valid subscription for this section. Please contact <a href="http://www.mv@meaningfulvision.co.uk" target="_blank" style={{textDecoration: 'none'}}>mv@meaningfulvision.co.uk</a> for assistance.</h3>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionError;
