import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { setFilterValue } from "../../redux/filterValues";

const Bar = (props) => {
  const { setActiveTab, filterValues1, calApi, switchFlag } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    setLoading(true);
    let temData = [];
    let tempCategory = [];
    if (props?.productData && props?.productData?.length > 0) {
      props?.productData?.forEach((e) => {
        if (props.productTrends) {
          if (e && e.FormattedDate) {
            temData.push(e.CountProduct);
            tempCategory.push(e.FormattedDate);
          }
        } else {
          if (e && e.BrandName) {
            temData.push(e.CountProduct);
            tempCategory.push(e.BrandName);
          }
        }
      });
    }
    setCategories(tempCategory);
    setData1(temData);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [props, calApi]);
  function convertDateFormat(dateString) {
    if (!dateString) {
      dateString = ["dateString"];
    }
    const [monthStr, yearStr] = dateString && dateString != undefined ? dateString.split("-") : ["Apr-24"].split("-");
    const monthIndex = new Date(Date.parse(monthStr + " 1, " + yearStr)).getMonth();
    const daysInMonth = new Date(parseInt(yearStr), monthIndex + 1, 0).getDate();
    const month = (monthIndex + 1).toString().padStart(2, "0");
    const startDate = `20${yearStr}-${month}-01`;
    const endDate = `20${yearStr}-${month}-${daysInMonth}`;
    return [startDate, endDate];
  }
  const handleBarClick = (event, chartContext, config) => {
    const category = config.w.config.xaxis.categories[config.dataPointIndex];

    let Ts = localStorage.getItem("Timescales"),
      Ms = localStorage.getItem("MarketSegment"),
      Cat = localStorage.getItem("Category"),
      Protein = localStorage.getItem("ProteinType"),
      Offer = localStorage.getItem("Offer");

    var temp = new Array();
    if (Ts) {
      var dateTrend = convertDateFormat(Ts);
    } else {
      var dateTrend = convertDateFormat(...filterValues1.Timescales);
    }

    if (Ms) {
      var temp = new Array();
      temp = Ms.split(",");
    }

    dispatch(
      setFilterValue({
        MarketSegment: [...temp],
        TimescalesTrend: [...dateTrend],
        CompetitiveSet: [category],
        Category: Cat ? [Cat] : [],
        ProteinType: Protein ? [Protein] : [],
        Offer: Offer ? [Offer] : [],
      })
    );
    if (switchFlag == false) {
      setActiveTab(2);
    } else {
      setActiveTab(1);
    }
  };

  const options = {
    chart: {
      height: 550,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
        export: {
          png: {
            filename: "Meaningful Vision",
          },
        },
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleBarClick(event, chartContext, config);
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
        columnWidth: categories.length > 10 ? "80%" : "20%",
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#1db9de", "#2ec551", "#f39c12", "#e74c3c"],
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: categories,
      position: "bottom",
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (val) => val,
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin></Spin>
        </div>
      )}
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Number of active promotions",
            data: data1,
          },
        ]}
        type='bar'
        height={550}
      />
    </div>
  );
};

export default Bar;
